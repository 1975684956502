import React, { Component } from "react";
import withRouter from "../helpers/withRouter";

interface InputBoxProps {
  onInputChange: (inputField: string) => void;
  updateParams: any;
  higherLevelProps: any;
  prompt: any;
}

interface InputBoxState {
  userInput: string;
  plusToggle: string;
}

class InputBox extends React.Component<InputBoxProps, InputBoxState> {
  state = { userInput: "", plusToggle: "" };

  constructor(props: InputBoxProps) {
    super(props);
    if (this.props.prompt && this.props.prompt !== "") {
      this.state.userInput = this.props.prompt;
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    // This code runs when the component updates (similar to useEffect with props or state dependencies)
    if (this.props.prompt !== prevProps.prompt) {
      this.setState({ userInput: this.props.prompt });
    }
  }

  handleKeyPress = (event: { key: string }) => {
    if (event.key === "Enter") {
      // The user pressed the "Enter" key, you can handle it here
      this.props.onInputChange(this.state.userInput);
      this.setState({ userInput: "" });
    }
  };

  userInput(userInput: string) {
    this.setState({ userInput });
  }

  onSend() {
    this.props.onInputChange(this.state.userInput);
    this.setState({ userInput: "" });
  }

  plusIcon() {
    const action: string = this.state.plusToggle == "action" ? "null" : "new";
    this.props.updateParams({
      action,
    });
    this.setState({
      plusToggle: this.state.plusToggle == "action" ? "null" : "action",
    });
  }

  render() {
    return (
      <>
        <div className="flex items-center input-box">
          <span className="hidden lg:block cursor-pointer opacity-90 add"></span>
          <span
            className="block lg:hidden cursor-pointer opacity-90 add"
            onClick={() => this.plusIcon()}
          >
            <img src="/assets/images/plus.svg" alt="" className="send-active" />
          </span>
          <input
            type="text"
            placeholder="Type a message here."
            value={this.state.userInput}
            onChange={(event) => this.userInput(event.target.value)}
            onKeyPress={this.handleKeyPress}
          />

          <span
            className="cursor-pointer opacity-90 send"
            onClick={() => this.onSend()}
          >
            <img src="/assets/images/send.svg" alt="" className="send-active" />
          </span>
        </div>
      </>
    );
  }
}

export default withRouter(InputBox);
