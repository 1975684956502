import React from "react";
import AdminHeader from "../components/header";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
} from "recharts";
import { Link } from "react-router-dom";
import { getUsers } from "../../../api/rest-apis";

interface UsersProps {}

interface UsersState {}

class Users extends React.Component<UsersProps, UsersState> {
  state = { users: [], usersGrowth: [] };

  chartData = Array.from({ length: 15 }, (_, index) => {
    // Generate data for each point
    const today = new Date(); // Get today's date
    const date = new Date(today.getTime() - index * 24 * 60 * 60 * 1000); // Subtract days from today
    const formattedDate = `${date.getDate().toString().padStart(2, "0")}-${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${date.getFullYear()}`;

    const users = Math.round(Math.random() * 40);

    return { date: formattedDate, users };
  });

  async componentDidMount() {
    try {
      const users: any = await getUsers();
      console.log(this.chartData);

      const newArr: any = [];

      Object.keys(users.usersGrowth).map((value) => {
        newArr.push({ date: value, users: users.usersGrowth[value] });
      });

      users.users.reverse();

      this.setState({ users: users.users, usersGrowth: newArr });
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    return (
      <>
        <div className="admin">
          <AdminHeader></AdminHeader>
          <div className="edit-character">
            <div className="title">Users</div>
            <div className="sub-title mt-3">
              Sorted from earliest recent registrations to oldest.
            </div>

            <table className="my-table" style={{ textAlign: "left" }}>
              <thead className="mt-4">
                <tr>
                  <th>NAME</th>
                  <th>PLAN</th>
                  <th>COUNTRY</th>
                  <th>CREATED</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.state.users.map((value: any, index) => (
                  <tr key={index} style={{ padding: "2rem !important" }}>
                    <td>
                      {value.firstName + " "}
                      {value.lastName ? value.lastName : ""}
                    </td>
                    <td>
                      <span className="table-status">{value.plan}</span>
                    </td>
                    <td>{value.country}</td>
                    <td>
                      {new Date(value.createdAt).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}{" "}
                      {new Date(value.createdAt).toLocaleTimeString("en-US", {
                        hour: "numeric",
                        minute: "numeric",
                        second: "numeric",
                        timeZoneName: "short",
                      })}
                    </td>
                    <td>
                      <Link
                        to={"/admin/user/" + value._id}
                        className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                      >
                        View
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="edit-character">
            <div className="title">Users Growth Rate</div>
            <div className="sub-title mt-3">
              Observe the impact of user registration growth over time.
            </div>

            <div className="single-character-chart">
              <LineChart width={600} height={300} data={this.state.usersGrowth}>
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <CartesianGrid strokeDasharray="3 3" />
                <Line type="monotone" dataKey="users" stroke="#8884d8" />
              </LineChart>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Users;
