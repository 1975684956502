import React from "react";
import { Link } from "react-router-dom";
import {
  cancelSubscription,
  createSubscription,
  getCurrentSubscription,
  getInvoice,
  getSessionUrlForCustomerPortal,
} from "../../../../api/rest-apis";
import DeleteModalHelper from "../../../../helpers/delete-modal";
import prices from "../../../../helpers/prices";
import Spin from "../../../../helpers/spin";
import { toastHelper } from "../../../../helpers/toast";

interface SubscriptionProps {}

interface SubscriptionState {}

interface Invoice {
  amount: number;
  status: any;
  createdAt: any;
}

class Subscription extends React.Component<
  SubscriptionProps,
  SubscriptionState
> {
  state = {
    recommendedPlan: 0,
    currentSubscription: "",
    currentSubscriptionAmount: 0,
    nextBillingCycle: "",
    invoices: [],
    purchases: [],
    showDeleteModal: false,
    canceled: false,
    scheduledToCancel: "",
    loading: true,
    trial_end: null,
    loadingPortal: false,
  };

  componentDidMount() {
    this.currentSubscription();
    this.invoice();
  }

  async currentSubscription() {
    try {
      const response: any = await getCurrentSubscription();
      const amountInCents = response.subscription.amount; // Assume amount is in cents
      const amountInDollars = (amountInCents / 100).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });

      this.setState({
        currentSubscription: response.subscription.planName,
        currentSubscriptionAmount: amountInDollars,
        nextBillingCycle: response.subscription.nextBillingDate,
        subscriptionStatus: response.subscription.subscriptionStatus,
        canceled: response.subscription.canceled,
        trial_end: response.subscription.trial_end,
        scheduledToCancel: response.subscription.scheduledToCancel
          ? response.subscription.scheduledToCancel
          : "",
        recommendedPlan: ["basic", "plus", "premium"].findIndex(
          (value) => value === response.subscription.planName.toLowerCase()
        ),
        loading: false,
      });
    } catch (error) {
      this.setState({
        currentSubscription: "Basic",
        currentSubscriptionAmount: "$0",
        nextBillingCycle: null,
        subscriptionStatus: null,
        canceled: true, // We don't want to show a cancel button when the customer is in Basic
        scheduledToCancel: null,
        recommendedPlan: 0,
        loading: false,
      });
      console.error(error);
      // Handle error
    }
  }

  async invoice() {
    try {
      const response: any = await getInvoice();

      const formattedInvoices = response.invoices.map((invoice: Invoice) => {
        const amountInDollars = (invoice.amount / 100).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });

        return {
          status:
            invoice.status.charAt(0).toUpperCase() + invoice.status.slice(1),
          amount: amountInDollars,
          createdAt: invoice.createdAt,
        };
      });

      this.setState({
        invoices: formattedInvoices,
        purchases: response.purchases,
      });
    } catch (error) {
      console.error(error);
      // Handle error
      this.setState({
        invoices: [],
      });
    }
  }

  async cancelSubscription() {
    const id = toastHelper.loading("Does cancelling...");
    this.setState({ showDeleteModal: false });
    try {
      await cancelSubscription();

      toastHelper.success("You have canceled your subscription!", id);

      this.currentSubscription();
    } catch (error) {
      toastHelper.error("Error on canceling subscription", id);
    }
  }

  async subscribe(plan: string) {
    this.setState({ loading: true });
    try {
      const response: any = await createSubscription(
        plan.toLowerCase()
          ? (process.env.REACT_APP_PLUS_PRICE_ID as string)
          : (process.env.REACT_APP_PREMIUM_PRICE_ID as string)
      );
      window.location.href = response.sessionUrl;
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ loading: false });
    }
  }

  async navigateToCustomerPortal() {
    this.setState({ loadingPortal: true });
    try {
      const response: any = await getSessionUrlForCustomerPortal();
      window.location.href = response.sessionUrl;
    } catch (error) {
      console.error(error);
      toastHelper.error("Error navigating to customer portal.");
    } finally {
      this.setState({ loadingPortal: false });
    }
  }

  render() {
    return (
      <>
        {this.state.loading && (
          <div className="flex justify-center">
            <Spin></Spin>
          </div>
        )}

        {!this.state.loading && (
          <div className="flex flex-wrap justify-start">
            <div className="lg:w-8/12">
              <div className="bookish-input-group mt-4">
                <p className="bookish-input-label">
                  Current Subscription <span className="required">*</span>
                </p>
                <div className="bookish-input-multi-label flex items-center">
                  <span className="left">
                    {this.state.currentSubscriptionAmount}
                    <span className="small">/month</span>
                  </span>
                  <span className="right ml-auto">
                    {this.state.currentSubscription}
                  </span>
                </div>
              </div>
              {!this.state.canceled &&
                this.state.currentSubscription !== "Basic" && (
                  <div className="bookish-input-group mt-4">
                    <p className="bookish-input-label flex">
                      <span>
                        {" "}
                        Next Billing Cycle <span className="required">*</span>
                      </span>
                      {this.state.trial_end && (
                        <span
                          className="trail-ends ml-5"
                          style={{
                            background: "#CFF5F6",
                            color: "#0257BD",
                            fontWeight: 600,
                            borderRadius: 10,
                            padding: ".3rem .7rem",
                            fontSize: "12px",
                          }}
                        >
                          Trial ends {this.state.trial_end}
                        </span>
                      )}
                    </p>
                    <input
                      type="text"
                      className="bookish-input-field"
                      name="firstName"
                      value={this.state.nextBillingCycle}
                      disabled
                    />
                  </div>
                )}

              {this.state.canceled &&
                this.state.currentSubscription !== "Basic" && (
                  <div className="bookish-input-group mt-4">
                    <p className="bookish-input-label flex align-center items-center justify-start">
                      <span className="bookish-input-label">
                        Scheduled to cancel on{" "}
                        <span className="required">*</span>
                      </span>

                      {new Date(this.state.scheduledToCancel) < new Date() && (
                        <span
                          className="trail-ends ml-5"
                          style={{
                            background: "#ff3f4b",
                            color: "#fff",
                            fontWeight: 700,
                            borderRadius: 10,
                            padding: ".3rem .7rem",
                            height: "1.5rem",
                            fontSize: "12px",
                            marginLeft: "3.5rem",
                          }}
                        >
                          Canceled
                        </span>
                      )}
                    </p>

                    <input
                      type="text"
                      className="bookish-input-field"
                      name="scheduledToCancel"
                      value={this.state.scheduledToCancel}
                      disabled
                    />
                  </div>
                )}

              {this.state.currentSubscription !== "Basic" && (
                <>
                  <div className="bookish-input-group mt-8">
                    <p
                      className="bookish-input-label"
                      style={{ fontSize: "1.2rem" }}
                    >
                      Invoice History
                    </p>
                    <p className="bookish-input-label">
                      Showing invoices within the past 12 months
                    </p>
                  </div>

                  <div className="overflow-x-auto w-[90%]">
                    <table className="my-table" style={{ textAlign: "left" }}>
                      <thead className="mt-4">
                        <tr>
                          <th>AMOUNT</th>
                          <th>STATUS</th>
                          <th>CREATED</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.invoices.map((value: Invoice, index) => (
                          <tr key={index} className="table-row mt-4">
                            <td>{value.amount}</td>
                            <td>
                              <span className="table-status">
                                {value.status}
                              </span>
                            </td>

                            <td>{value.createdAt}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              )}

              {this.state.purchases.length > 0 && (
                <>
                  <div className="mt-8">
                    <p style={{ fontSize: "1.2rem" }}>
                      ChatPoint Booster Purchased History
                    </p>
                  </div>

                  <div className="overflow-x-auto w-[90%]">
                    <table className="my-table" style={{ textAlign: "left" }}>
                      <thead className="mt-4">
                        <tr>
                          <th>DESCRIPTION</th>
                          <th>AMOUNT</th>
                          <th>PURCHASE DATE</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.purchases.map((value: any, index) => (
                          <tr key={index} className="table-row mt-4">
                            <td>{value.description}</td>
                            <td>
                              <span className="table-status">
                                ${value.amount_paid}
                              </span>
                            </td>

                            <td>{value.createdAt}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              )}

              <div className="flex flex-wrap mb-4 lg:gap-5">
                {this.state.currentSubscription != "Basic" && (
                  <div className="bookish-input-group ">
                    <button
                      className="bookish-cancel-btn"
                      style={{ width: "15rem", fontSize: "1rem" }}
                      onClick={() => this.navigateToCustomerPortal()}
                    >
                      {this.state.loadingPortal && (
                        <div className={"flex justify-center items-center"}>
                          <Spin></Spin>
                          <span style={{ color: "#444" }}>One Moment...</span>
                        </div>
                      )}

                      {!this.state.loadingPortal && (
                        <span style={{ color: "#444" }}>
                          Manage Subscription
                        </span>
                      )}
                    </button>
                  </div>
                )}
                {!this.state.canceled && (
                  <div className="bookish-input-group ">
                    <button
                      className="bookish-cancel-btn"
                      style={{ width: "15rem", fontSize: "1rem" }}
                      onClick={() => this.setState({ showDeleteModal: true })}
                    >
                      Cancel Subscription
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="mx-auto lg:ml-auto mb-4 lg:mb-0">
              <div
                className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow "
                style={{
                  width: "22rem",
                  margin: "auto",
                  border: "5px solid var(--primary-color)",
                  borderRadius: "30px",
                }}
              >
                <h3 className="mb-4 text-2xl font-semibold">
                  {prices[this.state.recommendedPlan].plan}
                </h3>
                <p
                  className="font-light text-gray-500 sm:text-lg "
                  style={{ whiteSpace: "pre-line" }}
                >
                  {prices[this.state.recommendedPlan].desc}
                </p>

                <div className="flex justify-center items-baseline my-8">
                  <span className="mr-2 text-5xl font-extrabold">
                    ${prices[this.state.recommendedPlan].price}
                  </span>
                  <span className="text-gray-500 ">/month</span>
                </div>
                <ul role="list" className="mb-8 space-y-4 text-left">
                  {prices[this.state.recommendedPlan].benefits.map(
                    (benefit) => (
                      <li className="flex items-center space-x-3">
                        {benefit.check ? (
                          <img src="/assets/images/check.svg" alt="" />
                        ) : (
                          <img src="/assets/images/x.svg" alt="" />
                        )}

                        <span>{benefit.content}</span>
                      </li>
                    )
                  )}
                </ul>
                <div className="bookish-input-group ">
                  <Link
                    to={"/price"}
                    // onClick={() =>
                    //   this.subscribe(prices[this.state.recommendedPlan].plan)
                    // }
                  >
                    <button
                      className="bookish-primary-btn"
                      style={{ width: "100%" }}
                    >
                      {/* {this.state.loading && (
                    <div className={"flex justify-center"}>
                      <Spin></Spin>
                      <span style={{ color: "#fff" }}>Processing...</span>
                    </div>
                  )}

                  {!this.state.loading && (
                    <span style={{ color: "#fff" }}>Change Subscription</span>
                  )} */}

                      <span style={{ color: "#fff" }}>Change Subscription</span>
                    </button>
                  </Link>
                </div>

                {/* <div className="bookish-input-group mt-4">
              <Link
                to={"/price"}
                className="font-medium text-blue-600  hover:underline"
              >
                View All Plans
              </Link>
            </div> */}
              </div>
            </div>
          </div>
        )}

        {this.state.showDeleteModal && (
          <>
            <DeleteModalHelper
              title={"Cancel Subscription"}
              message={
                "Are you sure you want to cancel your current subscription ? "
              }
              close_modal={() =>
                this.setState({
                  showDeleteModal: false,
                })
              }
              delete_it={() => this.cancelSubscription()}
            />
          </>
        )}
      </>
    );
  }
}

export default Subscription;
