import React from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";

const withRouter = (WrappedComponent: any) => (props: any) => {
  const { id, c } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const prompt = searchParams.get("prompt");
  const action = searchParams.get("action");
  const user_id = searchParams.get("user_id");
  const openChatPointBooster = searchParams.get("chatpoint-booster");

  const navigate = useNavigate();

  const updateParams = (newParams: any) => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    for (const key in newParams) {
      newSearchParams.set(key, newParams[key]);
    }

    navigate(location.pathname + "?" + newSearchParams.toString());
  };

  return (
    <WrappedComponent
      navigate={navigate}
      id={id}
      prompt={prompt}
      action={action}
      c={c}
      user_id={user_id}
      updateParams={updateParams}
      {...props}
      location={location}
      openChatPointBooster={openChatPointBooster}
    />
  );
};

export default withRouter;
