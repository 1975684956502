import * as React from "react";
import axios from "../../../helpers/axios";
import Spin from "../../../helpers/spin";
import { Character } from "../../../interfaces/rest-api";
import AssistantFileUpload from "../helpers/assistant-file-upload";
import AssistantForm from "./assistant-form";

interface File {
  filename: string;
  id: string;
}

interface CharacterFormProps {
  create: boolean;
  payload?: any;
  reverseFunction: (form: Character) => Promise<any>;
  clearForm?: boolean;
}

interface CharacterFormState {
  characterName: string;
  elevenlabs_voice_id: string;
  humanDescription: string;
  samplePrompts: string[];
  tags: string[];
  currentTag: string;
  currentPrompt: string;
  characterProfileImage: File | string | null;
  fileName: string;
  imageSrc: string;
  uploadPercentage: number;
  loading: boolean;
  assistants: any[];
}

class CharacterForm extends React.Component<
  CharacterFormProps,
  CharacterFormState
> {
  state: CharacterFormState = {
    characterName: "",
    elevenlabs_voice_id: "",
    humanDescription: "",
    samplePrompts: [
      "Sample Prompt goes like this",
      "Sample Prompt goes like this",
    ],
    tags: [],
    currentTag: "",
    currentPrompt: "",
    characterProfileImage: null,
    loading: false,
    fileName: "",
    imageSrc: "/assets/images/person.png",
    uploadPercentage: 0,
    assistants: [],
  };
  private inputRef: React.RefObject<HTMLInputElement>;

  constructor(props: CharacterFormProps) {
    super(props);

    this.reverseAssistantFunction = this.reverseAssistantFunction.bind(this);

    if (props.payload) {
      this.state = {
        ...this.state,
        ...{
          characterName: props.payload.character_name,

          elevenlabs_voice_id: props.payload.elevenlabs_voice_id
            ? props.payload.elevenlabs_voice_id
            : "pqHfZKP75CvOlQylNhV4",
          humanDescription: props.payload.human_description,

          tags: props.payload.tags,
          imageSrc: props.payload.character_profile,
          tools: this.props.payload.tools,
          assistants: this.props.payload.assistants,
        },
      };
    }

    this.inputRef = React.createRef();
    this.handleFileClick = this.handleFileClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  private handleFileClick(): void {
    if (this.inputRef.current) {
      this.inputRef.current.click();
    }
  }

  private handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.setState({
          imageSrc: reader.result as string,
          fileName: file.name,
        });
      };
      reader.readAsDataURL(file);
      this.uploadFile(file);
    }
  }

  private async uploadFile(file: any) {
    let data = new FormData();
    data.append("file", file);
    try {
      const response: any = await axios.post(
        "/api/v1/upload/character-profile",
        data,
        {
          onUploadProgress: (progressEvent: any) => {
            if (progressEvent.loaded === progressEvent.total) {
              return this.setState({ uploadPercentage: 100 });
            }
            this.setState({
              uploadPercentage: parseInt(
                Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                ).toString() // converting number to string
              ),
            });
          },
        }
      );

      this.setState({
        imageSrc: response.data.publicUrl,
      });
      setTimeout(
        () =>
          this.setState({
            uploadPercentage: 0,
          }),
        500
      );
    } catch (error) {
      console.log(error);
    }
  }

  handleAddTag = () => {
    const { currentTag, tags } = this.state;
    if (currentTag.trim() !== "") {
      this.setState({
        tags: [...tags, currentTag],
        currentTag: "",
      });
    }
  };

  handleTagInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ currentTag: event.target.value });
  };

  handleEnterKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      this.handleAddTag();
    }
  };

  removeTag(index: number): void {
    const arr = [...this.state.tags];
    arr.splice(index, 1);
    this.setState({ tags: arr });
  }

  handleSubmit = async () => {
    this.setState({ loading: true });
    const payload: any = {
      character_name: this.state.characterName,

      elevenlabs_voice_id: this.state.elevenlabs_voice_id,
      human_description: this.state.humanDescription,

      tags: this.state.tags,
      character_profile: this.state.imageSrc,

      assistants: this.state.assistants,
    };

    const response = await this.props.reverseFunction(payload);

    if (response) {
      const assistants: any[] = [...this.state.assistants];

      for (let index = 0; index < assistants.length; index++) {
        if (
          assistants[index].assistant_id !==
          response.result.assistants[index].assistant_id
        ) {
          assistants[index].assistant_id =
            response.result.assistants[index].assistant_id;
        }

        if (
          assistants[index].vector_store_id !==
          response.result.assistants[index].vector_store_id
        ) {
          assistants[index].vector_store_id =
            response.result.assistants[index].vector_store_id;
        }
      }

      this.setState({ assistants: [...assistants] });
      console.log("assistant updated", response.result.assistants);
    }
    this.setState({ loading: false });
    if (this.props.clearForm) {
      return;
      this.setState({
        characterName: "",
        elevenlabs_voice_id: "",
        humanDescription: "",
        samplePrompts: [
          "Sample Prompt goes like this",
          "Sample Prompt goes like this",
        ],
        tags: [],
        currentTag: "",
        currentPrompt: "",
        characterProfileImage: null,
        loading: false,
        fileName: "",
        imageSrc: "/assets/images/person.png",
        uploadPercentage: 0,
        assistants: [],
      });
    }
  };

  reverseAssistantFunction(assistants: any) {
    this.setState({ assistants });
  }

  render() {
    const {
      characterName,
      humanDescription,
      tags,
      currentTag,
      elevenlabs_voice_id,
    } = this.state;

    return (
      <>
        <div className="flex justify-center character-form">
          <div>
            <div className="title">
              {this.props.payload ? "Edit Character" : "Create Character"}
            </div>
            <div className="bookish-input-group mt-7">
              <p className="bookish-input-label">
                Character Name <span className="required">*</span>
              </p>
              <input
                type="text"
                className="bookish-input-field"
                value={characterName}
                onChange={(e) =>
                  this.setState({ characterName: e.target.value })
                }
              />
            </div>

            <div className="bookish-input-group mt-7">
              <p className="bookish-input-label">Elevenlabs voice Id</p>
              <input
                type="text"
                className="bookish-input-field"
                value={elevenlabs_voice_id}
                onChange={(e) =>
                  this.setState({ elevenlabs_voice_id: e.target.value })
                }
              />
            </div>

            <AssistantForm
              reverseFunction={this.reverseAssistantFunction}
              assistants={this.state.assistants}
              characterId={this.props.payload?._id}
              handleSubmit={() => this.handleSubmit()}
            ></AssistantForm>

            <div className="bookish-input-group mt-5">
              <p className="bookish-input-label">
                Human Description <span className="required">*</span>
              </p>
              <textarea
                className="bookish-input-field bookish-character-textarea"
                value={humanDescription}
                onChange={(e) =>
                  this.setState({ humanDescription: e.target.value })
                }
                rows={8}
                cols={8}
              />
            </div>

            {/* <div className="bookish-input-group mt-4 create-character-btn">
              <button
                className="bookish-primary-btn"
                onClick={this.handleSubmit}
                disabled={this.state.loading}
              >
                {this.state.loading && (
                  <div className={"flex justify-center items-center"}>
                    <Spin></Spin>
                    <span style={{ color: "#fff" }}>Processing...</span>
                  </div>
                )}

                {!this.state.loading && (
                  <span style={{ color: "#fff" }}>
                    {this.props.create ? "Create" : "Update"}
                  </span>
                )}
              </button>
            </div> */}

            <div style={{ marginLeft: "auto" }}>
              <div
                className="bookish-input-group mt-7"
                style={{ width: "90%" }}
              >
                <p className="bookish-input-label">
                  Tags <span className="required">*</span>
                </p>
                <div className="flex flex-wrap" style={{ width: "23rem" }}>
                  {tags.map((tag, index) => (
                    <span
                      key={index}
                      className="tag-view flex justify-between items-center"
                    >
                      <p>{tag}</p>
                      <img
                        className="cursor-pointer"
                        onClick={() => this.removeTag(index)}
                        src="/assets/images/close-icon.svg"
                        style={{ height: "1rem", marginLeft: ".8rem" }}
                        alt=""
                      />
                    </span>
                  ))}
                </div>
                <input
                  type="text"
                  className="bookish-input-field"
                  value={currentTag}
                  onChange={this.handleTagInputChange}
                  onKeyPress={this.handleEnterKeyPress}
                  placeholder="Type you new tags here."
                />
              </div>

              <div className="bookish-input-group mt-7">
                <p className="bookish-input-label">
                  Add character profile <span className="required">*</span>
                </p>

                <div
                  className="bookish-file-upload"
                  onClick={this.handleFileClick}
                >
                  <div className="flex justify-around pt-4">
                    <img
                      src={this.state.imageSrc}
                      alt=""
                      className="round-person"
                    />
                    <img
                      src={this.state.imageSrc}
                      alt=""
                      className="circle-person"
                    />
                  </div>

                  {this.state.uploadPercentage > 0 && (
                    <div
                      className=" bg-gray-200 rounded-full h-2.5 dark:bg-gray-700"
                      style={{ width: "75%", margin: "1rem auto 0 auto" }}
                    >
                      <div
                        className="h-2.5 rounded-full"
                        style={{
                          width: `${this.state.uploadPercentage}%`,
                          background: "var(--primary-color)",
                        }}
                      ></div>
                    </div>
                  )}
                  <p className="py-4">Choose Another image</p>
                  <input
                    type="file"
                    ref={this.inputRef}
                    onChange={this.handleChange}
                    style={{ display: "none" }}
                  />
                </div>
              </div>

              <div className="bookish-input-group mt-4 create-character-btn">
                <button
                  className="bookish-primary-btn"
                  onClick={this.handleSubmit}
                  disabled={this.state.loading}
                >
                  {this.state.loading && (
                    <div className={"flex justify-center items-center"}>
                      <Spin></Spin>
                      <span style={{ color: "#fff" }}>Processing...</span>
                    </div>
                  )}

                  {!this.state.loading && (
                    <span style={{ color: "#fff" }}>
                      {this.props.create ? "Create" : "Update"}
                    </span>
                  )}
                </button>
              </div>

              {/* <div className="bookish-input-group mt-4 create-character-mobile-btn">
                <button
                  className="bookish-primary-btn"
                  onClick={() => this.handleSubmit}
                  disabled={this.state.loading}
                >
                  {this.state.loading && (
                    <div className={"flex justify-center"}>
                      <Spin></Spin>
                      <span style={{ color: "#fff" }}>Processing...</span>
                    </div>
                  )}

                  {!this.state.loading && (
                    <span style={{ color: "#fff" }}>
                      {this.props.create ? "Create" : "Update"}
                    </span>
                  )}
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CharacterForm;
