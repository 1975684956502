import React from "react";
import { deleteCharacterOpenAIFile } from "../../../api/rest-apis";
import axios from "../../../helpers/axios";
import { toastHelper } from "../../../helpers/toast";
import { File } from "../interfaces/file";

interface AssistantFileUploadProps {
  files: File[];
  updateFiles: (files: File[]) => void;
}

interface AssistantFileUploadState {}

class AssistantFileUpload extends React.Component<
  AssistantFileUploadProps,
  AssistantFileUploadState
> {
  state = { files: [] };
  private inputRef: React.RefObject<HTMLInputElement>;

  constructor(props: AssistantFileUploadProps) {
    super(props);
    this.inputRef = React.createRef();
    this.handleFileClick = this.handleFileClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  private handleFileClick(): void {
    if (this.inputRef.current) {
      this.inputRef.current.click();
    }
  }

  private handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
    const file = e.target.files?.[0];

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      this.uploadFile(file);
    }
  }

  private async uploadFile(file: any) {
    const loading = toastHelper.loading("Adding ...");
    let data = new FormData();
    data.append("file", file);
    try {
      const response: any = await axios.post(
        "/api/v1/openai/upload-to-openai",
        data
      );

      const arr: File[] = [...this.props.files];
      arr.push({ filename: response.data.filename, id: response.data.id });
      this.props.updateFiles(arr);
      toastHelper.success("File added.", loading);
      // Reset the input after the file is uploaded
      if (this.inputRef.current) {
        this.inputRef.current.value = "";
      }
    } catch (error) {
      console.log(error);
      toastHelper.success("Error adding file.", loading);
    }
  }

  async deleteFile(id: string) {
    const loading = toastHelper.loading("Deleting ...");
    try {
      await deleteCharacterOpenAIFile(id);
      let arr: File[] = [...this.props.files];
      arr = arr.filter((file) => file.id !== id);
      this.props.updateFiles(arr);
      toastHelper.success("File deleted.", loading);
      // Reset the input after the file is uploaded
      if (this.inputRef.current) {
        this.inputRef.current.value = "";
      }
    } catch (error) {
      console.error(error);
      toastHelper.error("Couldn't not delete the file.", loading);
    }
  }

  render() {
    return (
      <>
        <div className="bookish-input-group mt-7 ">
          <p
            className="bookish-input-field flex "
            style={{
              background: "#fff",
              border: "none",
              padding: 0,
            }}
          >
            Files{" "}
            <span
              className="cursor-pointer ml-auto flex"
              onClick={this.handleFileClick}
            >
              <img
                src="/assets/images/file-add.svg"
                alt=""
                style={{ height: "2rem" }}
              />
              <p>Add</p>
            </span>
            <input
              type="file"
              ref={this.inputRef}
              onChange={this.handleChange}
              style={{ display: "none" }}
            />
          </p>
          <div className="files">
            {this.props.files.map((file: File) => (
              <div className="file my-2 flex justify-start">
                <img
                  src="/assets/images/file-icon.svg"
                  className="file-icon"
                ></img>
                <span className="ml-3">{file.filename}</span>
                <img
                  src="/assets/images/delete-file.svg"
                  className="ml-auto cursor-pointer"
                  onClick={() => this.deleteFile(file.id)}
                ></img>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }
}

export default AssistantFileUpload;
