import saveAs from "file-saver";
import React from "react";
import { getAdminAnalyticsForCharacter } from "../../../api/rest-apis";
import withRouter from "../../../helpers/withRouter";
import DateRangePicker from "../components/date-range-picker";
import AdminHeader from "../components/header";
import SingleCharacterChart from "../components/single-character-chart";

interface SingleCharacterAnalyticsProps {
  id: string;
}

interface SingleCharacterAnalyticsState {
  characterData: any;
  characterName: string;
  tags: { tag: string; active: boolean }[];
  selectedFilter: string;
  loading: boolean;
  yAxisValues: number[][]; // Array of arrays of numbers for each dataset
  xAxisLabels: string[]; // Array of strings for the X axis (e.g., months, days, weeks)
  datasetLabels: string[]; // Array of strings for dataset labels
  startDate: Date | null;
  endDate: Date | null;
}

class SingleCharacterAnalytics extends React.Component<
  SingleCharacterAnalyticsProps,
  SingleCharacterAnalyticsState
> {
  state = {
    characterData: {},
    characterName: "",
    tags: [
      { tag: "Basic", active: false },
      { tag: "Starter", active: false },
      { tag: "Plus", active: true },
      { tag: "Premium", active: false },
    ],
    yAxisValues: [],
    xAxisLabels: [],
    datasetLabels: [],
    selectedFilter: "Today and Yesterday",
    loading: false,
    startDate: null,
    endDate: null,
  };

  async componentDidMount() {
    await this.fetchData(this.state.selectedFilter);
  }

  async fetchData(
    filter: string,
    customRange: { start: null; end: null } = { start: null, end: null }
  ) {
    this.setState({ loading: true });
    try {
      const response: any = await getAdminAnalyticsForCharacter(this.props.id, {
        plans: this.state.tags
          .filter((tag) => tag.active)
          .map((tag) => tag.tag),
        timeRange: filter,
        customRange,
      });

      this.transformDataForChart(response.graph);
      this.setState({
        characterName: response.characterName,
        characterData: response.graph,
      });
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  }

  async toggleTag(index: number) {
    try {
      const arr = [...this.state.tags];
      arr[index].active = !arr[index].active;
      this.setState({ tags: arr });
      await this.fetchData(this.state.selectedFilter, {
        start: this.state.startDate,
        end: this.state.endDate,
      });
    } catch (error) {
      console.error(error);
    }
  }
  transformDataForChart(inputData: any) {
    // Initialize arrays to hold the transformed data
    const xAxisLabels: string[] = [];
    const yAxisValues: number[][] = []; // Now dynamically populated based on unique prompts
    const datasetLabels: Set<string> = new Set(); // Use a Set to ensure unique prompts

    // First, determine all unique prompts to create datasets for them
    Object.values(inputData).forEach((dayData: any) => {
      dayData.forEach((item: any) => {
        datasetLabels.add(item.prompt);
      });
    });

    // Initialize yAxisValues with an empty array for each unique prompt
    const prompts: string[] = Array.from(datasetLabels);
    prompts.forEach(() => yAxisValues.push([]));

    // Loop through the inputData to populate xAxisLabels and yAxisValues
    Object.entries(inputData).forEach((payl: any) => {
      const date = payl[0];
      const data = payl[1];
      xAxisLabels.push(date); // Add the date to the X-axis labels
      // Initialize a temporary storage for the tokens count of each prompt for the current date
      const tokensByPrompt: { [prompt: string]: number } = prompts.reduce(
        (acc, prompt) => ({ ...acc, [prompt]: 0 }),
        {}
      );

      // Populate tokensByPrompt with the actual tokens count for each prompt
      data.forEach((payload: any) => {
        const { prompt, tokens } = payload;
        tokensByPrompt[prompt] += tokens;
      });

      // For each prompt, add its tokens count to the corresponding dataset
      prompts.forEach((prompt, index) => {
        yAxisValues[index].push(tokensByPrompt[prompt]);
      });
    });

    // Now, we can set the state with the prepared data
    this.setState({
      xAxisLabels,
      yAxisValues,
      datasetLabels: Array.from(datasetLabels), // Convert Set of prompts to Array for labels
      loading: false,
    });
  }

  handleFilterChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const filter: string = event.target.value;
    this.setState({ selectedFilter: filter });
    await this.fetchData(filter); // Fetch data with the new filter
  };

  handleUpdateCustomDate = async (state: {
    startDate: Date | null;
    endDate: Date | null;
  }) => {
    this.setState({
      startDate: state.startDate,
      endDate: state.endDate,
    });

    const payload: any = {
      start: state.startDate,
      end: state.endDate,
    };

    await this.fetchData(this.state.selectedFilter, payload);
  };

  exportToCSV() {
    const data = this.transformDataForExporting(this.state.characterData);

    const csvData = this.convertToCSV(data);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    saveAs(blob, `${this.state.characterName} analytics.csv`);
  }

  convertToCSV(data: any[]): string {
    const header = Object.keys(data[0]).join(",");
    const rows = data.map((item) => Object.values(item).join(","));
    return header + "\n" + rows.join("\n");
  }

  transformDataForExporting(graph: any) {
    const transformedData: any = [];

    // Dynamically loading the keys (dates)
    Object.keys(graph).forEach((time) => {
      // Iterating over each prompt entry
      graph[time].forEach((entry: any) => {
        transformedData.push({
          time, // Dynamically loaded time (day or month)
          prompt: entry.prompt,
          tokens: entry.tokens,
        });
      });
    });

    return transformedData;
  }

  render() {
    return (
      <div className="admin">
        <AdminHeader></AdminHeader>
        <div className="edit-character">
          <div className="title">Character Analytics</div>
          <div className="sub-title mt-3">
            {this.state.characterName}: Usage over time
          </div>

          <div className="flex overflow-x-auto">
            <div className="inline-flex space-x-4 tags">
              {this.state.tags.map((tag: any, index: number) => (
                <div
                  key={index}
                  className={tag.active ? "tag active" : "tag"}
                  onClick={() => this.toggleTag(index)}
                >
                  {tag.tag}
                </div>
              ))}
            </div>
          </div>

          <div className="bookish-input-group my-4">
            <div className="sm:col-span-3">
              <p className="bookish-input-label">
                Filter <span className="required">*</span>
              </p>
              <div className="mt-2">
                <select
                  id="filter"
                  className="bookish-dropdown-field"
                  name="filter"
                  value={this.state.selectedFilter} // Bind select value to state
                  onChange={this.handleFilterChange} // Update state on change
                >
                  {[
                    "Today and Yesterday",
                    "Last 7 days",
                    "Last 30 days",
                    "This Month",
                    "Last Month",
                    "Last 12 months",
                    "This Year",
                    "Last Year",
                    "All the time",
                  ].map((value: string, index: number) => (
                    <option key={index}>{value}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="relative">
              <button
                style={{
                  background: "#8884D8",
                  color: "#fff",
                  fontWeight: 600,
                  padding: ".5rem",
                  borderRadius: 30,
                  position: "absolute",
                  right: 0,
                }}
                onClick={() => this.exportToCSV()}
              >
                Export
              </button>
            </div>

            <div className="sm:col-span-3">
              <p className="bookish-input-label">Custom</p>
              <DateRangePicker
                callback={(state: any) => this.handleUpdateCustomDate(state)}
              ></DateRangePicker>
            </div>
          </div>

          <div className="flex justify-center"></div>

          {/* {this.state.characterData.length > 0 && (
            <div className="mt-8">
              <SingleCharacterChart characterData={this.state.characterData} />
            </div>
          )} */}
          {!this.state.loading && (
            <SingleCharacterChart
              yAxisValues={this.state.yAxisValues}
              xAxisLabels={this.state.xAxisLabels}
              datasetLabels={this.state.datasetLabels}
            />
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(SingleCharacterAnalytics);
