const prices = [
  {
    price: 0,
    plan: "Basic",
    desc: "Quick sample",
    benefits: [
      { check: true, content: "1 ChatPoints per month" },
      { check: true, content: "Basic conversations" },
      { check: false, content: "Genius-level chatbots" },
      { check: false, content: "Personality Mode" },
      { check: false, content: "Steamy chats" },
      { check: false, content: "Get advice" },
      { check: false, content: "Charming & Engaging conversations" },
      { check: false, content: "Chatbots have voices" },
    ],
  },
  // {
  //   price: 4.99,
  //   plan: "Starter",
  //   desc: "Just a Taste",
  //   benefits: [
  //     { check: true, content: "75 ChatPoints per month" },
  //     { check: true, content: "Basic conversations" },
  //     { check: false, content: "Genius-level chatbots" },
  //     { check: false, content: "Personality Mode" },
  //     { check: false, content: "Steamy chats" },
  //     { check: false, content: "Get advice" },
  //     { check: false, content: "Charming & Engaging conversations" },
  //     { check: false, content: "Chatbots have voices" },
  //   ],
  // },
  {
    price: 4.99,
    plan: "Plus",
    desc: "Full Experience (Free trial)",
    benefits: [
      { check: true, content: "150 ChatPoints per month" },
      { check: true, content: "Genius-level chatbots" },
      { check: true, content: "Personality Mode" },
      { check: true, content: "Steamy chats" },
      { check: true, content: "Get advice" },
      { check: true, content: "Charming & Engaging conversations" },
      { check: false, content: "Chatbots have voices" },
    ],
  },
  {
    price: 14.99,
    plan: "Premium",
    desc: "Premium Experience",
    benefits: [
      { check: true, content: "450 ChatPoints per month" },
      { check: true, content: "Genius-level chatbots" },
      { check: true, content: "Personality Mode" },
      { check: true, content: "Steamy chats" },
      { check: true, content: "Get advice" },
      { check: true, content: "Charming & Engaging conversations" },
      { check: true, content: "Chatbots have voices" },
    ],
  },
];
export default prices;
