import * as React from "react";
import { adminGetModels, deleteCharacter } from "../../../api/rest-apis";
import DeleteModal from "../../../helpers/delete-modal";
import { toastHelper } from "../../../helpers/toast";
import "../css/assistant.css";
import AssistantFileUpload from "../helpers/assistant-file-upload";
import { File } from "../interfaces/file";

interface Assistant {
  _id?: string;
  assistant_id?: string;
  instructions: string;
  model: string;
  tools: { type: string }[];
  greeting: string;
  sample_prompts: string[];
  files: File[];
  for_plan: string[];
  tab_name: string;
}

interface AssistantFormProps {
  reverseFunction: (assistants: any) => any;
  handleSubmit: () => any;
  assistants: Assistant[];
  characterId: string;
}

interface AssistantFormState {
  assistants: Assistant[];
  activeTab: number;
  currentPrompts: string[];
  tabs: string[];
  modelList: any;
  showDeleteModal: boolean;
  deleteAssistantId: string | undefined;
}

class AssistantForm extends React.Component<
  AssistantFormProps,
  AssistantFormState
> {
  state = {
    assistants: [],
    activeTab: 0,
    currentPrompts: [""],
    tabs: [],
    modelList: [],
    showDeleteModal: false,
    deleteAssistantId: "",
    currentlyEditingSamplePrompt: "",
  };

  plans: string[] = ["basic", "plus", "premium"];

  constructor(props: any) {
    super(props);
    this.addTab = this.addTab.bind(this);
    this.deleteAssistant = this.deleteAssistant.bind(this);
  }

  async componentDidMount() {
    if (this.props.assistants.length > 0) {
      this.setState({
        assistants: this.props.assistants,
        tabs: this.props.assistants.map((assistant: any) => assistant.tab_name),
        activeTab: 0,
      });
    }

    try {
      const response: any = await adminGetModels();
      this.setState({ modelList: response.data });
    } catch (error) {
      console.error(error);
    }
  }

  componentDidUpdate(
    prevProps: Readonly<AssistantFormProps>,
    prevState: Readonly<AssistantFormState>,
    snapshot?: any
  ): void {
    const stateAssistants: Assistant[] = [...this.state.assistants];

    if (stateAssistants.length != prevProps.assistants.length) {
      // Note: If the state length is greather it's mean the user just add a new assistant. Not in the DB
      console.log("Length is different");
    }

    for (let index = 0; index < this.props.assistants.length; index++) {
      const assistant = this.props.assistants[index];

      if (
        stateAssistants.length === this.props.assistants.length &&
        (assistant._id !== stateAssistants[index]._id ||
          assistant.assistant_id !== stateAssistants[index].assistant_id)
      ) {
        this.setState({
          assistants: this.props.assistants,
          tabs: this.props.assistants.map(
            (assistant: any) => assistant.tab_name
          ),
          activeTab: 0,
        });
      }
    }
  }

  addTab() {
    const newAssistant = {
      greeting: "",
      instructions: "",
      model: "",
      tools: [],
      sample_prompts: [
        "Sample Prompt goes like this",
        "Sample Prompt goes like this",
      ],
      files: [],
      for_plan: [],
      tab_name: `New Assistant ${this.state.tabs.length}`,
    };

    const assistants: Assistant[] = [...this.state.assistants];
    assistants.push(newAssistant);

    const tabs: string[] = [...this.state.tabs];
    tabs.push(newAssistant.tab_name);

    const activeTab = tabs.length - 1;

    this.setState({ assistants, tabs, activeTab });

    this.props.reverseFunction(assistants);
  }

  async deleteAssistant() {
    const id = toastHelper.loading("Deleting ...");
    try {
      const arr: Assistant[] = [...this.state.assistants];

      arr.splice(this.state.activeTab, 1);

      if (arr.length === 0) {
        const newAssistant = {
          greeting: "",
          instructions: "",
          model: "",
          tools: [],
          files: [],
          sample_prompts: [],
          for_plan: [],
          tab_name: "New Assistant",
        };

        arr.push(newAssistant);
      }

      if (this.state.deleteAssistantId === undefined) {
        this.props.reverseFunction(arr);

        this.setState({
          showDeleteModal: false,
          deleteAssistantId: "",
          activeTab: arr.length - 1,
          assistants: arr,
          tabs: arr.map((assistant: any) => assistant.tab_name),
        });

        toastHelper.success("Assistant have been deleted!", id);
        return;
      }

      await deleteCharacter(
        this.props.characterId,
        this.state.deleteAssistantId
      );

      toastHelper.success("Assistant have been deleted!", id);

      this.props.reverseFunction(arr);

      this.setState({
        showDeleteModal: false,
        deleteAssistantId: "",
        activeTab: arr.length - 1,
        assistants: arr,
        tabs: arr.map((assistant: any) => assistant.tab_name),
      });
      setTimeout(async () => await this.componentDidMount(), 500);
    } catch (error) {
      toastHelper.error("Error on deleting assistant", id);
      console.error(error);
      this.setState({ showDeleteModal: false, deleteAssistantId: "" });
    }
  }

  handleTabClick(index: number) {
    this.setState({ activeTab: index });
  }

  handleInputChange = (field: string, value: string) => {
    setTimeout(
      () =>
        this.setState({
          tabs: this.state.assistants.map(
            (assistant: any) => assistant.tab_name
          ),
        }),
      100
    );

    this.setState((prevState: any) => {
      const assistants = [...prevState.assistants];
      assistants[prevState.activeTab][field] = value;
      return { assistants };
    });

    this.props.reverseFunction(this.state.assistants);
  };

  removeSamplePrompt(index: number): void {
    const assistants: Assistant[] = [...this.state.assistants];
    assistants[this.state.activeTab].sample_prompts.splice(index, 1);
    this.setState({ assistants });
  }

  handleAddSamplePrompt = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      const currentPrompts: string[] = [...this.state.currentPrompts];
      const assistants: Assistant[] = [...this.state.assistants];
      assistants[this.state.activeTab].sample_prompts.push(
        currentPrompts[this.state.activeTab]
      );
      if (currentPrompts[this.state.activeTab].trim() !== "") {
        this.setState({ assistants });
      }
    }
  };

  handlePromptInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentPrompts: string[] = [...this.state.currentPrompts];
    currentPrompts[this.state.activeTab] = event.target.value;
    console.log(currentPrompts);
    this.setState({ currentPrompts });
  };

  toggleTool(toolType: any) {
    this.setState((prevState: any) => {
      const assistants = [...prevState.assistants];
      const tools = assistants[prevState.activeTab].tools;
      const toolIndex = tools.findIndex((tool: any) => tool.type === toolType);

      if (toolIndex > -1) {
        // Tool exists, remove it
        tools.splice(toolIndex, 1);
      } else {
        // Tool doesn't exist, add it
        tools.push({ type: toolType });
      }

      return { assistants };
    });

    this.props.reverseFunction(this.state.assistants);
  }

  handleFilesChange(files: any) {
    this.setState((prevState: any) => {
      const assistants = [...prevState.assistants];
      assistants[prevState.activeTab].files = files;
      return { assistants };
    });

    this.props.reverseFunction(this.state.assistants);
    setTimeout(() => this.props.handleSubmit(), 1000);
  }

  togglePlan(plan: any) {
    this.setState((prevState: any) => {
      const assistants = [...prevState.assistants];
      const currentPlans = assistants[prevState.activeTab].for_plan;
      const planIndex = currentPlans.indexOf(plan);

      if (planIndex > -1) {
        // Plan exists, remove it
        currentPlans.splice(planIndex, 1);
      } else {
        // Plan doesn't exist, add it
        currentPlans.push(plan);
      }

      return { assistants };
    });

    this.props.reverseFunction(this.state.assistants);
  }

  render() {
    const { activeTab, assistants, modelList, tabs, showDeleteModal } =
      this.state;
    const activeAssistant: Assistant = assistants[activeTab];

    return (
      <div className="mt-8">
        <p className="bookish-input-label">Assistants</p>
        <div className="assistants-edit">
          <div className="tabs flex">
            {tabs.map((tab, index) => (
              <div
                key={index}
                className={`tab flex items-center ${
                  activeTab === index ? "active-tab" : ""
                }`}
                onClick={() => this.handleTabClick(index)}
              >
                {tab}
              </div>
            ))}
            <div className="tab flex items-center" onClick={this.addTab}>
              <img src="/assets/images/plus.svg" alt="" />
              <span className="ml-2">Add Assistant</span>
            </div>
          </div>

          {this.state.tabs.length > 0 && (
            <div className="tab-content">
              <div className="delete-btn flex justify-end">
                <button
                  onClick={() =>
                    this.setState({
                      showDeleteModal: true,
                      deleteAssistantId: activeAssistant.assistant_id,
                    })
                  }
                >
                  <img
                    className="edit-icon"
                    src="/assets/images/delete-red.svg"
                    alt=""
                  />
                </button>
              </div>
              <div className="bookish-input-group mt-5">
                <p className="bookish-input-label">
                  Tab Name <span className="required">*</span>
                </p>
                <input
                  type="text"
                  className="bookish-input-field"
                  value={activeAssistant.tab_name}
                  onChange={(e) =>
                    this.handleInputChange("tab_name", e.target.value)
                  }
                />
              </div>
              <div className="bookish-input-group mt-5">
                <p className="bookish-input-label">
                  Greeting <span className="required">*</span>
                </p>
                <textarea
                  className="bookish-input-field bookish-character-textarea"
                  value={activeAssistant.greeting}
                  onChange={(e) =>
                    this.handleInputChange("greeting", e.target.value)
                  }
                  rows={4}
                />
              </div>
              <div className="bookish-input-group mt-5">
                <p className="bookish-input-label">
                  Instructions <span className="required">*</span>
                </p>
                <textarea
                  className="bookish-input-field bookish-character-textarea"
                  value={activeAssistant.instructions}
                  onChange={(e) =>
                    this.handleInputChange("instructions", e.target.value)
                  }
                  rows={4}
                />
              </div>
              <div className="bookish-input-group mt-7">
                <p className="bookish-input-label">
                  Model <span className="required">*</span>
                </p>
                <select
                  className="bookish-select-box"
                  value={activeAssistant.model}
                  onChange={(e) =>
                    this.handleInputChange("model", e.target.value)
                  }
                >
                  {modelList.map((model: any, index) => (
                    <option key={index} value={model.id}>
                      {model.id}
                    </option>
                  ))}
                </select>
              </div>

              <div className="bookish-input-group mt-7">
                <p className="bookish-input-label">Tools</p>
                <div
                  className="bookish-on-off flex"
                  onClick={() => this.toggleTool("file_search")}
                >
                  <p className="label mt-1">Retrieval</p>
                  <div
                    className={`rectangle ml-auto ${
                      activeAssistant.tools.some(
                        (tool: any) => tool.type === "file_search"
                      )
                        ? "rectangle-active"
                        : ""
                    }`}
                  >
                    <div
                      className={`ellipse ${
                        activeAssistant.tools.some(
                          (tool) => tool.type === "file_search"
                        )
                          ? "ellipse-active"
                          : ""
                      }`}
                    ></div>
                  </div>
                </div>
              </div>
              {activeAssistant.tools.some(
                (tool) => tool.type === "file_search"
              ) && (
                <AssistantFileUpload
                  files={activeAssistant.files}
                  updateFiles={(files) => this.handleFilesChange(files)}
                />
              )}

              <div className="bookish-input-group mt-7">
                <p className="bookish-input-label">
                  Personality Mode <span className="required">*</span>
                </p>
                <div style={{ width: "23rem" }}>
                  {activeAssistant.sample_prompts.map((value, index) => (
                    <div className="sample-prompt flex justify-between items-center">
                      <p>{value}</p>
                      <img
                        className="cursor-pointer"
                        onClick={() => this.removeSamplePrompt(index)}
                        src="/assets/images/close-icon.svg"
                        style={{ height: "1rem" }}
                        alt=""
                      />
                    </div>
                  ))}
                </div>
                <input
                  type="text"
                  className="bookish-input-field"
                  value={this.state.currentPrompts[this.state.activeTab]}
                  onChange={this.handlePromptInputChange}
                  onKeyPress={this.handleAddSamplePrompt}
                  placeholder="Type sample prompt here."
                />
              </div>
              <div className="bookish-input-group mt-8">
                <p className="bookish-input-label">
                  Assign to a subscription plan
                  <span className="required">*</span>
                </p>
                <div className="grid grid-cols-2 gap-4">
                  {this.plans.map((plan, index) => (
                    <div key={index} className="relative flex gap-x-3">
                      <div className="flex h-6 items-center">
                        <input
                          id={`plan-${plan}-${index}`}
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          checked={activeAssistant.for_plan.includes(plan)}
                          onChange={() => this.togglePlan(plan)}
                        />
                      </div>
                      <div className="text-sm leading-6">
                        <label
                          htmlFor={`plan-${plan}-${index}`}
                          className="font-medium text-gray-900"
                        >
                          {plan}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>

        {showDeleteModal && (
          <DeleteModal
            title="Deleting assistant"
            message="Are you absolutely sure you want to delete the current assistant? This action is not reversible."
            close_modal={() => this.setState({ showDeleteModal: false })}
            delete_it={() => {
              this.deleteAssistant();
            }}
          />
        )}
      </div>
    );
  }
}

export default AssistantForm;
